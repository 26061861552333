<template>
  <div class="w-4/5 mx-auto flex flex-col gap-2">
    <div>
      <BtnGoogleSignin
        signup
        :disabled="!user.consents.terms"
        @success="handleGoogleSuccess"
      />
      <div class="relative inline-flex items-center justify-center w-full">
        <hr class="w-full h-px my-6 bg-neutral-80 border-0" />
        <span
          class="absolute w-max max-w-full text-center px-3 text-black -translate-x-1/2 bg-white left-1/2"
          >{{ $t('shared.google_signup.sso_or_form') }}
        </span>
      </div>
    </div>
    <form
      class="h-full flex flex-col gap-4 mb-2 items-center tabletPortrait:justify-start"
    >
      <Label required :title="$t('shared.label.email_address')" class="w-full">
        <InputFieldEmail
          id="email"
          :placeholder="$t('shared.label.email_address')"
          :value="user.email"
          with-icon
          @inputChange="onEmailChange"
      /></Label>
      <Label required :title="$t('shared.label.password')" class="w-full">
        <InputFieldSecret
          id="password"
          :placeholder="$t('shared.label.password')"
          autocomplete="new-password"
          :value="user.password"
          show-strength-meter
          @change="onPasswordChange"
        />
      </Label>
      <Label
        required
        :title="$t('shared.placeholder.confirm_password')"
        class="w-full"
      >
        <InputFieldSecret
          id="password_confirmation"
          :placeholder="$t('shared.placeholder.confirm_password')"
          autocomplete="new-password"
          :value="user.password_confirmation"
          @change="onConfirmPassword"
        />
        <p
          v-if="invalid.passwordsMatch"
          data-cy="password_validation"
          class="text-sm text-error-100"
        >
          {{ $t('shared.validation.password.not_match') }}
        </p>
      </Label>
    </form>
    <div class="w-full flex flex-col items-center">
      <AgreementOption :value="user.consents.terms" @onClick="toggleTerms">
        <i18n-t
          keypath="shared.terms.conditions.text"
          tag="span"
          class="text-sm pl-2"
        >
          <template #terms_conditions>
            <span
              class="no-underline hover:underline text-primary-100 cursor-pointer"
              @click="onTermsConditionsLink"
              >{{ $t('shared.terms.condition.btn.terms_conditions') }}
            </span>
          </template>
          <template #privacy_policy>
            <span
              class="no-underline hover:underline text-primary-100 cursor-pointer"
              @click="onPrivacyClick"
              >{{ $t('customer.privacy.consent.privacy_policy') }}
            </span>
          </template>
          <template #marketing>
            <span
              class="no-underline hover:underline text-primary-100 cursor-pointer"
              @click="$emit('openModal', 'marketing')"
              >{{ $t('customer.privacy.consent.marketing') }}
            </span>
          </template>
          <template #product_categories>
            <span
              class="no-underline hover:underline text-primary-100 cursor-pointer"
              @click="$emit('openModal', 'marketing')"
              >{{ $t('customer.privacy.consent.product_categories') }}
            </span>
          </template>
        </i18n-t>
      </AgreementOption>
      <RequiredInfoLabel class="mt-6 self-baseline" />
      <BtnsWrapperVertical class="mb-6">
        <BtnPrimary
          class="w-full"
          data-cy="get_started"
          :title="$t('shared.button.get_started')"
          :disabled="enableBtn"
          @click="onClick"
        />
        <fwb-p class="text-center">
          {{ $t('customer.onboarding.btn.login') }}
          <fwb-a
            class="no-underline hover:underline text-primary-100"
            @click="$router.push({ name: 'BaseLogin' })"
            >{{ $t('shared.button.here') }}</fwb-a
          >
        </fwb-p>
      </BtnsWrapperVertical>
    </div>
  </div>
</template>
<script setup>
import AgreementOption from '@/components/shared/agreement/AgreementOption'
import BtnGoogleSignin from '@/components/shared/btn/BtnGoogleSignin'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import InputFieldEmail from '@/components/shared/input/InputFieldEmail'
import InputFieldSecret from '@/components/shared/input/InputFieldSecret'
import Label from '@/components/shared/label/Label'
import RequiredInfoLabel from '@/components/shared/label/RequiredInfoLabel'
import { validateEmail } from '@/helpers/validation'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import domains from '@/utils/domains.json'
import axios from 'axios'
import { FwbA, FwbP } from 'flowbite-vue'
import _ from 'underscore'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const props = defineProps({
  userInfo: Object
})
const emit = defineEmits(['show', 'openModal', 'marketing'])

const user = ref({
  referral: 'sales',
  email: '',
  password: '',
  password_confirmation: '',
  consents: {
    terms: false,
    marketing: true
  }
})
const invalid = ref({
  email: false,
  passwordLength: false,
  passwordsMatch: false
})

const isOnProduction = computed(() => store.getters['account/isOnProduction'])

const enableBtn = computed(() => {
  if (
    invalid.value.email === false &&
    invalid.value.passwordLength === false &&
    invalid.value.passwordsMatch === false &&
    user.value.consents.terms &&
    user.value.email &&
    user.value.password &&
    user.value.password_confirmation
  )
    return false
  return true
})

const warning = (payload) => store.dispatch('toast/warning', payload)
const success = (payload) => store.dispatch('toast/success', payload)

function onEmailChange(text) {
  user.value.email = text.value
  invalid.value.email = !validateEmail(text.value)
}
const onPasswordChange = _.debounce(function (password) {
  if (password) {
    user.value.password = password
  }
}, 700)
const onConfirmPassword = _.debounce(function (password) {
  if (password) {
    user.value.password_confirmation = password
    user.value.password_confirmation !== user.value.password
      ? (invalid.value.passwordsMatch = true)
      : (invalid.value.passwordsMatch = false)
  }
}, 700)
function toggleTerms(bool) {
  user.value.consents.terms = bool
}
async function onClick() {
  let removeFirst = user.value.email.split('@')
  let removeSecond = removeFirst[1].split('.')

  let publicDomain = domains.includes(removeSecond[0])

  if (publicDomain) {
    warning({ message: $t('shared.toast.error.business_email') })
  } else {
    if (isOnProduction.value) {
      const formData = {
        fields: [
          {
            name: 'email',
            value: user.value.email
          }
        ],
        context: {
          hutk: $cookies.get('hubspotutk'),
          pageUri: window.location.href,
          pageName: 'Onobarding usage',
          ipAddress: null
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: 'I agree to EasyTranslate to store and process my personal data.',
            communications: [
              {
                value: true,
                subscriptionTypeId: 11090282,
                text: `EasyTranslate needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.`
              }
            ]
          }
        }
      }
      await axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/8932080/22ed3ed4-e60b-40a7-a9a2-1b303045732f`,
          formData,
          {
            headers: {
              accept: 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            isPublicRequest: true,
            withCredentials: false
          }
        )
        .then(() => {
          emit('show', { nextView: 'account', profile: user.value })
        })
        .catch((err) => {
          warning({
            message: $t('shared.toast.error.business_email')
          })
        })
    } else if (
      process.env.VUE_APP_ENV === 'test' ||
      process.env.VUE_APP_ENV === 'local'
    ) {
      const formData = {
        fields: [
          {
            name: 'email',
            value: user.value.email
          }
        ],
        context: {
          hutk: $cookies.get('hubspotutk'),
          pageUri: window.location.href,
          pageName: 'Onobarding usage',
          ipAddress: null
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: 'I agree to EasyTranslate to store and process my personal data.',
            communications: [
              {
                value: true,
                subscriptionTypeId: 11090282,
                text: `EasyTranslate needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.`
              }
            ]
          }
        }
      }
      await axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/9248979/1577fdd9-8948-45e3-b287-aceb27d910a5`,
          formData,
          {
            headers: {
              accept: 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            isPublicRequest: true,
            withCredentials: false
          }
        )
        .then(() => {
          emit('show', { nextView: 'account', profile: user.value })
        })
        .catch((err) => {
          warning({
            message: $t('shared.toast.error.business_email')
          })
        })
    } else {
      emit('show', { nextView: 'account', profile: user.value })
    }
  }
}

function onTermsConditionsLink() {
  window.open(
    'https://easytranslate.com/en/terms-and-conditions-localisation-software-platform/',
    '_blank'
  )
}

function onPrivacyClick() {
  window.open('https://www.easytranslate.com/en/privacy-policy/', '_blank')
}

function handleGoogleSuccess() {
  success({ message: $t('customer.signup.success_toast') })
  setTimeout(() => {
    window.location.href = `http://${process.env.VUE_APP_COOKIE_DOMAIN}/${i18n.locale.value}`
  }, 1000)
}

watch(
  () => props.userInfo,
  (newValue) => {
    if (Object.keys(newValue.profile).length > 0) {
      user.value.consents = newValue.profile.consents
      user.value.email = newValue.profile.email
      user.value.password = newValue.profile.password
      user.value.password_confirmation = newValue.profile.password_confirmation
    }
  }
)
</script>
